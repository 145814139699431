import React, { useState, useEffect } from "react";
import "swiper/swiper-bundle.css";
import classes from "../../css/prueba.module.css";
import dividerh from "../../img/divider-h.svg";
import li from "../../img/li-icon.svg";
import whatsaap from "../../img/whatsapp-logo.svg";
import cafetera from "../../img/exp-cafetera-D-1.png";
import i18next from "i18next";
import SEO from "../functions/MetaTags";

const PlanDetail = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive((prev) => !prev);
  };

  const toggleCollapse = () => {
    if (isMobile) {
      setIsCollapsed(!isCollapsed);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileScreen = window.innerWidth <= 768;
      setIsMobile(isMobileScreen);
      if (!isMobileScreen) {
        setIsCollapsed(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const lang = i18next.language.split("-")[0] || "en";

  const content = {
    en: {
      name: "EcoRolling",
      seoTitle: "Ecorolling | Coffee Experience",
      image:
        "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
      title: <>Coffee Experience</>,
      seodescription:
        "Discover vibrant bird species and unmatched biodiversity in Colombia’s Coffee Region, home to over 245 species—a birdwatcher's paradise.",
      description: (
        <>
          Welcome to our exciting plan that will take you
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          to discover the incredible biodiversity of the{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Coffee Cultural Landscape of Colombia, exploring the{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          charming destinations of Salento, Cocora Valley, and{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Filandia!{" "}
          <span className={classes["desktop-break"]}>
            <br />
            <br />
          </span>
          After a day full of exciting{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          adventures exploring the biodiversity of the Coffee{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Cultural Landscape, we invite you to rest and relax in the{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          comfort of Hotel Caffetos Montenegro{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Quindio.
        </>
      ),
      includes: [
        "<strong>Airport or hotel pickup</strong> To start your adventure with comfort and convenience.",
        "Complete peace of mind with our <strong>medical insurance</strong> so you can explore worry-free.",
        "Delight in a <strong>delicious snack</strong> that will give you the energy to enjoy your day to the fullest.",
        "Enjoy a <strong>traditional lunch</strong> that will let you savor the best of local cuisine.",
        "<strong>Included transfer</strong> from Salento to the stunning Cocora Valley and then to the charming town of Filandia.",
        "Rest and relax in the comfort of a <strong>wonderful hotel within the coffee plantations of Quindío</strong>, where you'll spend an unforgettable night.",
      ],

      collapsible: (
        <>
          This Experience{" "}
          <span className={classes["mobile-break"]}>
            <br />
          </span>{" "}
          Includes{" "}
          <span
            className={
              isActive
                ? classes["chevron-icon-active"]
                : classes["chevron-icon"]
            }
          ></span>
        </>
      ),
      desc_end: (
        <>
          Join us and let the magic of this extraordinary destination{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          captivate you. Don't miss this{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          unforgettable adventure!
        </>
      ),
      emblematicSpeciesTitle: (
        <>
          {" "}
          Hotel inside <br /> the coffee paradise{" "}
        </>
      ),
      emblematicSpeciesDesc: (
        <>
          We guarantee you an experience that you'll remember
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          forever!
        </>
      ),
    },
    es: {
      seoTitle: "Ecorolling | Experiencia Cafetera",
      name: "EcoRolling",
      image:
        "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
      seodescription: ".",
      title: <>Experiencia Cafetera</>,
      description: (
        <>
          ¡Bienvenido a nuestro emocionante plan que te{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          llevará a descubrir la increíble biodiversidad del{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Eje Cafetero de Colombia, explorando los{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          encantadores destinos de Salento, Valle del{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Cocora y Filandia!{" "}
          <span className={classes["desktop-break"]}>
            <br />
            <br />
          </span>
          Después de un día lleno de emocionantes{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          aventuras explorando la biodiversidad del Eje{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Cafetero, te invitamos a descansar y relajarte en{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          el confort del Hotel Caffetos Montenegro{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Quindio.
        </>
      ),
      includes: [
        "<strong>Recolección en el aeropuerto u hotel</strong> Para comenzar tu aventura con comodidad y conveniencia.",
        "Tranquilidad absoluta con nuestro <strong>Seguro médico</strong> que puedas explorar sin preocupaciones.",
        "Deleitate con un <strong>delicioso refrigerio</strong> que te brindara energia para disfrutar al maximo tu dia",
        "Disfruta de un <strong>almuerzo tradicional</strong> que te permitira saborear lo mejor de la gastronomia local",
        "<strong>Traslado incluido</strong> desde Salento hasta el impresionante Valle del Cocora y luego hacia el encantador municipio de Filandia.",
        "Descansa y relájate en el confort del  <strong> un hotel dentro de los cafetales quindianos</strong>, donde pasarás una noche inolvidable.",
      ],

      collapsible: (
        <>
          Esta Experiencia{" "}
          <span className={classes["mobile-break"]}>
            <br />
          </span>{" "}
          Incluye{" "}
          <span
            className={
              isActive
                ? classes["chevron-icon-active"]
                : classes["chevron-icon"]
            }
          ></span>
        </>
      ),
      desc_end: (
        <>
          ¡Únete a nosotros y deja que la magia de este destino{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          extraordinario te envuelva. ¡No te pierdas esta aventura{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          inolvidable!
        </>
      ),
      emblematicSpeciesTitle: (
        <>
          {" "}
          Hotel dentro <br />
          del paraiso cafetal{" "}
        </>
      ),
      emblematicSpeciesDesc: (
        <>
          ¡Te garantizamos una experiencia que recordarás por
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          siempre!
        </>
      ),
    },
  };

  const currentContent = content[lang] || content.en;

  return (
    <div>
      <SEO
        title={currentContent.seoTitle}
        description={currentContent.seodescription}
        image={currentContent.image}
        name={currentContent.name}
      />
      <main className={classes["main-content"]}>
        <section
          className={`${classes["flex-row"]} ${classes["section-banner"]} ${classes.avistamiento}`}
        >
          <div className={classes["img-left"]}>
            <img src={cafetera} alt="Imagen del plan" className={classes.img} />
          </div>
          <div className={`${classes["desc-right"]} ${classes["flex-col"]}`}>
            <h2>{content[lang].title}</h2>
            <img
              src={dividerh}
              alt="divider"
              className={`${classes.img} ${classes.divider}`}
            />
            <p>{content[lang].description}</p>
            <img
              src={dividerh}
              alt="divider"
              className={classes["div-inverse"]}
            />
          </div>
        </section>

        <section
          className={`${classes["flex-col"]} ${classes["plan-details"]}`}
        >
          <img
            src={dividerh}
            alt="divider"
            className={`${classes.img} ${classes.divider}`}
          />

          <div className={`${classes["collapsible-wrapper"]} ${classes.flex}`}>
            <div
              className={`${classes["flex-col"]} ${classes["collapsible-entry"]} ${classes["collapsible-entry-v1"]}`}
            >
              <button
                type="button"
                className={`${classes.collapsible} ${classes.avistamiento}`}
                onClick={toggleCollapse}
                disabled={!isMobile}
              >
                <h2 className={classes.colltitle}
                onClick={toggleActive}>
                  {content[lang].collapsible}
                </h2>
              </button>
              <ul
                className={`${classes.content} ${classes["content-v1"]} ${
                  isCollapsed ? classes["content-hidden"] : classes.show
                }`}
              >
                {content[lang].includes.map((item, index) => (
                  <li
                    key={index}
                    className={`${classes.flex} ${classes["li-v1"]}`}
                  >
                    <img src={li} alt="icono" className={classes["img-icon"]} />
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                  </li>
                ))}
                <a href="./plan-detail.html">
                  <button
                    className={`${classes["main-btn"]} ${classes["btn-centre"]} ${classes["whatsap-mobile"]}`}
                  >
                    <img
                      src={whatsaap}
                      alt="whatsapp-logo"
                      className={classes["img-whatssap"]}
                    />
                    <h2 className={classes.contactitle}>
                      {i18next.t("Ponte en Contacto")}
                    </h2>
                  </button>
                </a>
              </ul>
            </div>
          </div>

          <a href="./plan-detail.html">
            <button
              className={`${classes["main-btn"]} ${classes["btn-centre"]} ${classes["whatsap-desktop"]}`}
            >
              <img
                src={whatsaap}
                alt="whatsapp-logo"
                className={classes["img-whatssap"]}
              />
              <h2 className={classes.contactitle}>
                {i18next.t("Ponte en Contacto")}{" "}
              </h2>
            </button>
          </a>
          <div
            className={`${classes.flex} ${classes["mt-1"]} ${classes["mb-2"]} ${classes["plan-subtitle"]}`}
          >
            <h3 className={classes["end-message"]}>{content[lang].desc_end}</h3>
          </div>
          <img
            src={dividerh}
            alt="divider"
            className={classes["div-inverse"]}
          />
        </section>
        {/* Sección de Especies Emblemáticas */}
        <section
          className={`${classes["flex-col"]} ${classes["plan-detail-banner"]} ${classes["plan-detail-banner-caffetos"]}`}
        >
          <div
            className={`${classes["hero-area-desc"]} ${classes.flex} ${classes["des-caffetos"]}`}
          >
            <h2
              className={`${classes["air-title"]} ${classes["title-desktop"]}`}
            >
              {content[lang].emblematicSpeciesTitle}
            </h2>
            <p>{content[lang].emblematicSpeciesDesc}</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PlanDetail;
