import classes from '../css/styles.module.css';
// import classes from '../css/Footer.module.css';
import instagram from '../img/instagram-logo-blue.svg';
import whatsaap from '../img/whatsapp-logo-blue.svg';
import facebook from '../img/facebook-logo-blue.svg';
import i18next from 'i18next';

const Footer = () => {
   
    return (
        <footer>
            <div className={classes['flex-col']}>
        <div className={`${classes['border-topnav']} ${classes.flex}`}>
            <span className={classes['border-yellow']}></span>
            <span className={classes['border-blue']}></span>
            <span className={classes['border-red']}></span>
        </div>
        <div className={classes['footer-content']}>
 
            <div className={`${classes.flex} ${classes['footer-credits']}`}>
                <a  className={classes.bh_credits} href="https://backendhelpers.co/" target="_blank" rel="noopener noreferrer">
                    <p className={classes.credits}>{i18next.t('® 2024 Backend Helpers Limited')}</p>
                </a>
            </div> 
         
            <div className={classes['flex-col']}>     
                <h4 className={classes['footer-wording']}>{i18next.t('Contáctanos en redes sociales')}</h4>
                <div className={`${classes['flex-row']}  ${classes['social-media']}`}>
                <a href="https://www.instagram.com/ecorolling_/" target="_blank" rel="noreferrer" >
                        <img src={instagram} alt="instagram" className={classes['img-sm']}/>
                    </a>
                    <a href="https://wa.me/353896000322?text=Hello%20from%20EcoRolling!%20How%20can%20we%20help%20you%20today?%20Feel%20free%20to%20ask%20us%20anything%20about%20our%20eco-friendly%20tours%20or%20the%20beautiful%20Coffee%20Axis%20of%20Colombia." target="_blank" rel="noreferrer" >
                        <img src={whatsaap} alt="whatsapp" className={classes['img-sm']}/>
                    </a>
                    <a href="https://www.facebook.com/people/Eco-Rolling/61556791555946/" target="_blank" rel="noreferrer" >
                        <img src={facebook} alt="facebook" className={classes['img-sm']}/>
                    </a>
                </div>
            </div>
        </div>
    </div>

 </footer>
    );
};

export default Footer;