import React from 'react';
import './App.css';
import Layout from './Components/Layout';
import { HelmetProvider } from 'react-helmet-async';

function App() {

  return (
    <HelmetProvider>
    <Layout/>
    </HelmetProvider>
  );
}

export default App;
