import React, { useState, useEffect } from "react";
import "swiper/swiper-bundle.css";
import classes from "../../css/prueba.module.css";
import dividerh from "../../img/divider-h.svg";
import li from "../../img/li-icon.svg";
import whatsaap from "../../img/whatsapp-logo.svg";
import safari from "../../img/safari-nocturno-D-1.png";
import i18next from "i18next";
import SEO from "../functions/MetaTags";

const PlanDetail = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive((prev) => !prev);
  };

  const toggleCollapse = () => {
    if (isMobile) {
      setIsCollapsed(!isCollapsed);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileScreen = window.innerWidth <= 768;
      setIsMobile(isMobileScreen);
      if (!isMobileScreen) {
        setIsCollapsed(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const lang = i18next.language.split("-")[0] || "en";

  const content = {
    en: {
      name: "EcoRolling",
      seoTitle: "Ecorolling | Experience of the jungle lights",
      image:
        "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
      seodescription: ".",
      title: (
        <>
          Experience the Thrill of{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          the Night Safari{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          an Adventure Illuminated{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          by the Stars
        </>
      ),
      description: (
        <>
          Embark on a unique and captivating
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          experience in Colombia's Coffee Region{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          with our exciting Night Safari.{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Discover the magic that awakens{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          when the sun sets and the mysteries of the{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          jungle reveal their splendor in the dark.{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
        </>
      ),
      includes: [
        "<strong>Magical Duration</strong> Enjoy 4 hours of pure adventure under the stars. Starts at 6:00 pm for an unforgettable experience.",
        "<strong>Expert Guide by Your Side</strong> Our bilingual expert guides will take you on a fascinating journey, sharing knowledge and secrets of the surroundings.",
        "<strong>Exploration Tools</strong> Equipped with a flashlight and magnifying glass, you'll be ready to uncover the hidden treasures of the night.",
        "<strong>Energy in Motion</strong> Recharge with a delicious snack that will keep you active throughout the experience.",
        "<strong>Comfort and Safety</strong> With included rain boots, you can explore without worries, while our insurance gives you peace of mind every step of the way.",
        "<strong>Unforgettable Memories</strong> Capture every magical moment with photographs of the experience to take unforgettable memories with you.",
        "<strong>Transportation Included</strong> Relax and enjoy the journey with our transportation service that will take you back and forth in comfort and safety.",
      ],

      collapsible: (
        <>
          This Experience{" "}
          <span className={classes["mobile-break"]}>
            <br />
          </span>{" "}
          Includes{" "}
          <span
            className={
              isActive
                ? classes["chevron-icon-active"]
                : classes["chevron-icon"]
            }
          ></span>
        </>
      ),
      desc_end: (
        <>
          Join us and let the magic of this extraordinary destination{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          surround you. Don't miss out on this unforgettable{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          adventure!
        </>
      ),
      emblematicSpeciesTitle: (
        <>
          {" "}
          Under{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          the Starry Sky
        </>
      ),
      emblematicSpeciesDesc: (
        <>
          Our <strong>Night Safari</strong> will take you{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          through paths illuminated{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          only by the <strong>stars</strong>.
        </>
      ),
    },
    es: {
      name: "EcoRolling",
      seoTitle: "Ecorolling | Experimenta las radiantes luces de la selva",
      image:
        "https://eco-rolling.com/static/media/Avistamiento-banner-D-1.e842420bc24c71ac97c6.png",
      seodescription: ".",
      title: (
        <>
          Vive la Emocion del{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Safari Nocturno{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          una Aventura Iluminada{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          por las Estrellas
        </>
      ),
      description: (
        <>
          Embárcate en una experiencia única y
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          cautivadora en el Eje Cafetero de Colombia{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          con nuestro emocionante Safari Nocturno.{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Descubre la magia que se despierta{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          cuando el sol se pone y los misterios de la{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          selva revelan su esplendor en la oscuridad.{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
        </>
      ),
      includes: [
        "<strong>Duración Mágica:</strong> Disfruta de 4 horas de pura aventura bajo las estrellas. Inicio a las 6:00 pm para una experiencia inolvidable.",
        "<strong>Guía Experta a tu Lado:</strong> Nuestros guías bilingües especializados te llevarán por un viaje fascinante, compartiendo conocimientos y secretos del entorno.",
        "<strong>Herramientas de Exploración:</strong> Equipado con linterna y lupa, estarás preparado para descubrir los tesoros ocultos de la noche.",
        "<strong>Energía en Movimiento:</strong> Recarga tus energías con un delicioso refrigerio que te mantendrá activo durante toda la experiencia.",
        "<strong>Comodidad y Seguridad:</strong> Con botas pantaneras incluidas, podrás explorar sin preocupaciones, mientras nuestro seguro te brinda tranquilidad en cada paso.",
        "<strong>Recuerdos Inolvidables:</strong> Captura cada momento mágico con fotografías de la experiencia, para llevar contigo recuerdos eternos.",
        "<strong>Transporte Incluido:</strong> Relájate y disfruta del viaje con nuestro servicio de transporte que te llevará de ida y vuelta con comodidad y seguridad.",
      ],

      collapsible: (
        <>
          Esta Experiencia{" "}
          <span className={classes["mobile-break"]}>
            <br />
          </span>{" "}
          Incluye
          <span
            className={
              isActive
                ? classes["chevron-icon-active"]
                : classes["chevron-icon"]
            }
          ></span>
        </>
      ),
      desc_end: (
        <>
          ¡Únete a nosotros y deja que la magia de este destino{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          extraordinario te envuelva. ¡No te pierdas esta aventura{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>{" "}
          inolvidable!
        </>
      ),
      emblematicSpeciesTitle: (
        <>
          {" "}
          Bajo el Manto <br /> Estrellado
        </>
      ),
      emblematicSpeciesDesc: (
        <>
          Nuestro <strong>Safari Nocturno</strong> te llevará a{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          través de caminos iluminados{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          únicamente por las <strong>estrellas</strong>.
        </>
      ),
    },
  };

  const currentContent = content[lang] || content.en;

  return (
    <div>
      <SEO
        title={currentContent.seoTitle}
        description={currentContent.seodescription}
        image={currentContent.image}
        name={currentContent.name}
      />
      <main className={classes["main-content"]}>
        <section
          className={`${classes["flex-row"]} ${classes["section-banner"]} ${classes.avistamiento}`}
        >
          <div className={classes["img-left"]}>
            <img src={safari} alt="Imagen del plan" className={classes.img} />
          </div>
          <div className={`${classes["desc-right"]} ${classes["flex-col"]}`}>
            <h2>{content[lang].title}</h2>
            <img
              src={dividerh}
              alt="divider"
              className={`${classes.img} ${classes.divider}`}
            />
            <p>{content[lang].description}</p>
            <img
              src={dividerh}
              alt="divider"
              className={classes["div-inverse"]}
            />
          </div>
        </section>

        <section
          className={`${classes["flex-col"]} ${classes["plan-details"]}`}
        >
          <img
            src={dividerh}
            alt="divider"
            className={`${classes.img} ${classes.divider}`}
          />

          <div className={`${classes["collapsible-wrapper"]} ${classes.flex}`}>
            <div
              className={`${classes["flex-col"]} ${classes["collapsible-entry"]} ${classes["collapsible-entry-v1"]}`}
            >
              <button
                type="button"
                className={`${classes.collapsible} ${classes.avistamiento}`}
                onClick={toggleCollapse}
                disabled={!isMobile}
              >
                <h2 className={classes.colltitle}
                 onClick={toggleActive}>
                  {content[lang].collapsible}
                </h2>
              </button>
              <ul
                className={`${classes.content} ${classes["content-v1"]} ${
                  isCollapsed ? classes["content-hidden"] : classes.show
                }`}
              >
                {content[lang].includes.map((item, index) => (
                  <li
                    key={index}
                    className={`${classes.flex} ${classes["li-v1"]}`}
                  >
                    <img src={li} alt="icono" className={classes["img-icon"]} />
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                  </li>
                ))}
                <a href="./plan-detail.html">
                  <button
                    className={`${classes["main-btn"]} ${classes["btn-centre"]} ${classes["whatsap-mobile"]}`}
                  >
                    <img
                      src={whatsaap}
                      alt="whatsapp-logo"
                      className={classes["img-whatssap"]}
                    />
                    <h2 className={classes.contactitle}>
                      {i18next.t("Ponte en Contacto")}
                    </h2>
                  </button>
                </a>
              </ul>
            </div>
          </div>

          <a href="./plan-detail.html">
            <button
              className={`${classes["main-btn"]} ${classes["btn-centre"]} ${classes["whatsap-desktop"]}`}
            >
              <img
                src={whatsaap}
                alt="whatsapp-logo"
                className={classes["img-whatssap"]}
              />
              <h2 className={classes.contactitle}>
                {i18next.t("Ponte en Contacto")}{" "}
              </h2>
            </button>
          </a>
          <div
            className={`${classes.flex} ${classes["mt-1"]} ${classes["mb-2"]} ${classes["plan-subtitle"]}`}
          >
            <h3 className={classes["end-message"]}>{content[lang].desc_end}</h3>
          </div>
          <img
            src={dividerh}
            alt="divider"
            className={classes["div-inverse"]}
          />
        </section>
        <section
          className={`${classes["flex-col"]} ${classes["plan-detail-banner"]} ${classes["plan-detail-banner-luces-selva"]}`}
        >
          <div className={`${classes["hero-area-desc"]} ${classes.flex}`}>
            <h2
              className={`${classes["air-title"]} ${classes["title-desktop"]}`}
            >
              {content[lang].emblematicSpeciesTitle}
            </h2>
            <p>{content[lang].emblematicSpeciesDesc}</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PlanDetail;
