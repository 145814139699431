import { useState } from 'react';
import classes from '../css/styles.module.css';
// import classes from '../css/Header.module.css';
import logo from '../img/logo.png';
import { Link } from 'react-router-dom';
import whatsaap from '../img/whatsapp-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NavigationLinks from './NavLinks';
import i18next from 'i18next';

const Header = ({homeUrl}) => {

  const [isOpen, setIsOpen] = useState(false);

  function burgerToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <header>
      <div className={classes['flex-col']} />
      <div className={`${classes.navigation} ${classes.flex}`}>
        <div className={classes['logo-desktop']}>
          <Link to={homeUrl}>
            <img src={logo} alt="Ecorolling logo" className={classes['img-logo']} />
          </Link>
        </div>
        <div className={classes.navbtn}>
          <div className={classes['logo-mobile']}>
            <Link to={homeUrl}>
              <img src={logo} alt="Ecorolling logo" className={classes['img-logo']} />
            </Link>
          </div>
          <nav className={`${classes.topnav} ${classes.flex} ${isOpen ? classes.responsive : ''} ${classes.myTopnav} ${classes["title-responsive"]}`}>
            <NavigationLinks homeUrl={homeUrl} isOpen={isOpen} burgerToggle={burgerToggle}/>         
            <a href={i18next.t('whatsappLink')} target="_blank" rel="noreferrer" >
              <button className={`${classes['main-btn']} ${classes['whatsap-logo']}`}>
                <img src={whatsaap} alt="whatsapp-logo" className={classes['img-xxs']} /> WhatsApp
              </button>
            </a>
            <Link to="#" className={classes.icon} onClick={burgerToggle}>
              <FontAwesomeIcon icon={faBars} />
            </Link>
          </nav>
        </div>
      </div>
      <div className={`${classes['border-topnav']} ${classes.flex}`}>
        <span className={classes['border-yellow']}></span>
        <span className={classes['border-blue']}></span>
        <span className={classes['border-red']}></span>
      </div>
    </header>
  );
};

export default Header;