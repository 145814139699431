import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from '../css/styles.module.css';
import i18next from 'i18next';

const NavigationLinks = ({ isOpen, burgerToggle }) => {
  const location = useLocation(); 
  const currentLanguage = i18next.language.split('-')[0];
  const currentPath = location.pathname; 

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); 
    };

    window.addEventListener('resize', handleResize); 

    return () => {
      window.removeEventListener('resize', handleResize); 
    };
  }, []);

  const links = [
    { to: '/', text: i18next.t('Inicio'), className: classes.inicio },
    {
      to: `/${currentLanguage}/experiences`,
      text: isMobile ? i18next.t('Planes') : i18next.t('Nuestros Planes'), 
      className: classes.planes,
    },
    // { to: `/${currentLanguage}/contacto`, text: t('Contácto') },
    { to: `/${currentLanguage}/questions-answers`, text: i18next.t('Preguntas & Respuestas') },
    { to: `/${currentLanguage}/about-us`, text: i18next.t('Nosotros') },
  ];

  return (
    <>
      {links.map((link, index) => (
        <Link
          key={index}
          to={link.to}
          className={`${classes.navitems} ${link.className} ${ 
            (link.to === `/${currentLanguage}/experiences` && currentPath.startsWith(link.to)) || 
            currentPath === link.to ? classes.active : ''}`}
        >
          {link.text}
        </Link>
      ))}
    </>
  );
};

export default NavigationLinks;
