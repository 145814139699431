import React from 'react';
import classes from '../../css/styles.module.css';
import nosotros1 from '../../img/nosotros-1.png';
import verticalDivider from '../../img/vertical-divider.svg';
import nosotros2 from '../../img/nosotros-2.png';
import nosotros3 from '../../img/nosotros-3.png';
import i18next from 'i18next';

const Us = () => {

  const lang = i18next.language.split('-')[0] || 'en';

  const content = {
    en: {
      title: "About Us",
      card1_title: "Discover Colombian Biodiversity",
      card1_desc: "From the lush Amazon jungle to the majestic Andes mountains, every destination we explore is imbued with unique wildlife and breathtaking landscapes.",
      card2_title: "Tailor-Made Experiences",
      card2_desc: "We carefully design each experience to match your preferences and allow you to discover Colombia’s diversity in an authentic way.",
      card3_title: "Your Journey, Our Priority",
      card3_desc: "From the first consultation to the farewell, your satisfaction is our top priority. We strive to provide personalized and attentive service.",
      section_desc: "Get ready to experience unforgettable moments in the Coffee Axis of this amazing country!",
    },
    es: {
      title: "Nosotros",
      card1_title: "Descubre la Biodiversidad Colombiana",
      card1_desc: "Desde la exuberancia de la selva amazónica hasta las majestuosas montañas de los Andes, cada destino que exploramos está impregnado de vida salvaje única y paisajes impresionantes.",
      card2_title: "Experiencias a Medida",
      card2_desc: "Diseñamos cuidadosamente cada experiencia para que se adapte a tus preferencias y descubras la diversidad de Colombia de una manera auténtica.",
      card3_title: "Tu Viaje, Nuestra Prioridad",
      card3_desc: "Desde la primera consulta hasta la despedida, tu satisfacción es nuestra máxima prioridad. Nos esforzamos por brindarte un servicio personalizado y atento.",
      section_desc: "¡Prepárate para vivir momentos inolvidables en el Eje Cafetero de este asombroso país!",
    }
  };

  return (
    <section>
      <section className={`${classes.nosotros} ${classes['flex-col']}`}>
        <h1 className={classes['project-title']}>{content[lang].title}</h1>
        <div className={`${classes.flex} ${classes['card-container']} ${classes['mt-1']} ${classes['mb-2']} ${classes['slideshow-container']}`}>
          <div className={`${classes['nosotros-card']} ${classes['flex-col']} ${classes.mySlides} ${classes.fade}`}>
            <img src={nosotros1} alt="biodiversity" className={`${classes.img} ${classes['mb-1']}`} />
            <div className="">
              <h2 className={classes['card-title']}>{content[lang].card1_title}</h2>
              <p className={`${classes['card-description']} ${classes["card-steps"]}`}>
                {content[lang].card1_desc}
              </p>
            </div>
          </div>
          <div className={classes['vertical-divider']}>
            <img src={verticalDivider} alt="vertical-divider" className={classes.img} />
          </div>
          <div className={`${classes['nosotros-card']} ${classes['flex-col']} ${classes.mySlides} ${classes.fade}`}>
            <img src={nosotros2} alt="biodiversity" className={`${classes.img} ${classes['mb-1']}`} />
            <div className="">
              <h2 className={classes['card-title']}>{content[lang].card2_title}</h2>
              <p className={`${classes['card-description']} ${classes["card-steps"]}`}>
                {content[lang].card2_desc}
              </p>
            </div>
          </div>
          <div className={classes['vertical-divider']}>
            <img src={verticalDivider} alt="vertical-divider" className={classes.img} />
          </div>
          <div className={`${classes['nosotros-card']} ${classes['flex-col']} ${classes.mySlides} ${classes.fade}`}>
            <img src={nosotros3} alt="biodiversity" className={`${classes.img} ${classes['mb-1']}`} />
            <div className="">
              <h2 className={classes['card-title']}>{content[lang].card3_title}</h2>
              <p className={`${classes['card-description']} ${classes["card-steps"]}`}>
                {content[lang].card3_desc}
              </p>
            </div>
          </div>
        </div>
        <div className={`${classes.flex} ${classes['flex-col']} ${classes['section-desc']}`}>
          <h2 className={`${classes['card-title']} ${classes["card-section1"]}`}>{content[lang].section_desc}</h2>
          <a href={i18next.t('whatsappLink')}target="_blank" rel="noreferrer">
            <button className={`${classes['main-btn']} ${classes.showmore}`}>
            {i18next.t('View More')}
            </button>
          </a>
        </div>
      </section>
    </section>
  );
};

export default Us;
