import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import SpanishTranslations from './locales/es.json';
import EnglishTranslations from './locales/en.json';

const isPrerendering = process.env.REACT_APP_PRERENDER === 'true'; // eslint-disable-line no-unused-vars

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translation: EnglishTranslations },
      es: { translation: SpanishTranslations },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      checkWhitelist: true,
    },    
    whitelist: ['en', 'es'], 
    load: 'languageOnly', 
  });

export default i18n;
