import "swiper/swiper-bundle.css";
import classes from "../../css/prueba.module.css";
import dividerh from "../../img/divider-h.svg";
import PR from "../../img/P&R.jpg";
import React, { useState } from "react";
import i18next from "i18next";
import li from '../../img/li-icon.svg';
import SEO from "../functions/MetaTags";

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const lang = i18next.language.split("-")[0] || "en";

  const content = {
    en: {
      seoTitle: "Ecorolling | Questions & Answers",
      title: (
        <>
          Questions &{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Answer
        </>
      ),
        questions: [
          {
            question: (
              <>
                Is it safe to travel  <span className={classes["mobile-break"]}><br /></span> to Colombia? 
              </>
            ),
            answer:
              "Yes, Colombia has seen significant improvements in safety over the years. However, like any destination, it is recommended to take standard precautions and be informed about the areas you plan to visit.",
          },
          {
            question: (
              <>
                What is the best <span className={classes["mobile-break"]}><br /></span> time to visit  <span className={classes["mobile-break"]}><br /></span> Colombia? 
              </>
            ),
            answer:
              "The best time depends on the region, but generally, the dry seasons (December to March and July to August) are ideal.",
          },
          {
            question: (
              <>
                Do I need specific vaccines before <span className={classes["desktop-break"]}>
            <br />
          </span> traveling to  <span className={classes["mobile-break"]}><br /></span>Colombia? 
              </>
            ),
            answer:
              "It is recommended to check for required vaccines ahead of time. Yellow fever and other vaccines may be required depending on the region you plan to visit.",
          },
          {
            question: (
              <>
                What is the official currency of Colombia <br /> and how should I handle money? 
              </>
            ),
            answer:
              "The official currency is the Colombian peso. You can exchange money at airports, banks, and exchange houses. Credit cards are widely accepted.",
          },
          {
            question: (
              <>
                Is it necessary to learn Spanish <span className={classes["desktop-break"]}>
            <br />
          </span>to travel  <span className={classes["mobile-break"]}><br /></span>to Colombia? 
              </>
            ),
            answer:
              "It is not necessary, but knowing a few basic phrases in Spanish can help with communication. In tourist areas, many people speak English, but learning some local expressions can be useful.",
          },
          {
            question: (
              <>
                How is public transportation  <span className={classes["mobile-break"]}><br /></span> in Colombia? 
              </>
            ),
            answer:
              "The main cities have public transportation systems. There are also taxis and shared transportation services. For longer distances, there are domestic flights and buses.",
          },
          {
            question: (
              <>
                What type of plugs are used in  Colombia <span className={classes["desktop-break"]}>
            <br />
          </span><span className={classes["mobile-break"]}><br /></span>and do I need an adapter? 
              </>
            ),
            answer:
              "Colombia uses type A and B plugs. If your devices use other types, you will need an adapter.",
          },
          {
            question: (
              <>
                What typical Colombian dishes  should I try? 
              </>
            ),
            answer:
              "Some traditional dishes include bandeja paisa, arepas, empanadas, and ajiaco. The culinary variety is broad and delicious.",
          },
          {
            question: (
              <>
                Can I drink  <span className={classes["mobile-break"]}><br /></span>tap  water in  Colombia? 
              </>
            ),
            answer:
              "In most major cities, tap water is potable. However, in more rural or remote areas, it is recommended to drink bottled water.",
          },
          {
            question: (
              <>
                How is the internet <br /> connection in Colombia? 
              </>
            ),
            answer:
              "The internet connection is generally good in urban areas. Many places offer free wifi, and you can get a local SIM card for mobile data during your stay.",
          },
          {
            question: (
              <>
                What is the best time to visit  <span className={classes["mobile-break"]}><br /></span> <span className={classes["desktop-break"]}>
            <br />
          </span>the Coffee  Region  <span className={classes["mobile-break"]}><br /></span>of Colombia? 
             </>
            ),
            answer:
              "The Coffee Region has a pleasant climate year-round, but the dry season between December and March is ideal to avoid rain.",
          },
          {
            question: (
              <>
                What are the  <span className={classes["mobile-break"]}><br /></span> main tourist attractions <span className={classes["desktop-break"]}>
            <br />
          </span>in  the Coffee Region? 
              </>
            ),
            answer:
              "Some highlights include coffee plantations, the Cocora Valley, and picturesque towns like Salento.",
          },
          {
            question: (
              <>
                How do you get to the Coffee Region <span className={classes["desktop-break"]}>
            <br />
          </span> from major cities in Colombia? 
              </>
            ),
            answer:
              "You can fly to the airports in Armenia, Pereira, or Manizales. There are also bus options from nearby cities.",
          },
          {
            question: (
              <>
                Are there guided tours to learn about  <span className={classes["desktop-break"]}>
            <br />
          </span> <span className={classes["mobile-break"]}><br /></span> the coffee process  <span className={classes["mobile-break"]}><br /></span>in  the Coffee  <span className={classes["mobile-break"]}><br /></span>Region? 
              </>
            ),
            answer:
              "Yes, there are numerous tours that take you to coffee plantations where you can learn about cultivation, harvesting, and coffee production.",
          },
          {
            question: (
              <>
                What is the best way to explore <span className={classes["desktop-break"]}>
            <br />
          </span>the landscapes of the Coffee Region?
              </>
            ),
            answer:
              "You can take hiking, horseback riding, or jeep tours to explore the hills, plantations, and valleys of the region.",
          },
          {
            question: (
              <>
                What are the  <span className={classes["mobile-break"]}><br /></span> major festivals <span className={classes["desktop-break"]}>
            <br />
          </span>or events   in the  <span className={classes["mobile-break"]}><br /></span>Coffee Region? 
              </>
            ),
            answer:
              "Events like the Manizales Fair offer cultural and festive experiences. There are also local celebrations in nearby towns.",
          },
          {
            question: (
              <>
                How is  <span className={classes["mobile-break"]}><br /></span>the accommodation <span className={classes["desktop-break"]}>
            <br />
          </span> offer  in the  <span className={classes["mobile-break"]}><br /></span>Coffee Region? 
              </>
            ),
            answer:
              "There are a variety of options, from traditional farms to boutique accommodations. You can find everything from budget options to luxurious ones.",
          },
          {
            question: (
              <>
                What are the  <span className={classes["mobile-break"]}><br /></span>options for  adventure  lovers <span className={classes["desktop-break"]}>
            <br />
          </span> in the  <span className={classes["mobile-break"]}><br /></span> Coffee Region? 
              </>
            ),
            answer:
              "In addition to coffee tours, there are opportunities for hiking, paragliding, and exploring the mountains.",
          },
        ],
      },
    es: 
    {
      seoTitle: "Ecorolling | Preguntas & Respuestas",
      title: (
        <>
          Preguntas &{" "}
          <span className={classes["desktop-break"]}>
            <br />
          </span>
          Respuestas
        </>
      ),
      questions: [
        {
          question: (
            <>
              ¿Es seguro viajar  <span className={classes["mobile-break"]}><br /></span> a Colombia? 
            </>
          ),
          answer:
            "Sí, Colombia ha experimentado una notable mejora en términos de seguridad en los últimos años. Sin embargo, como en cualquier destino, es recomendable tomar precauciones normales y estar informado sobre las áreas a visitar.",
        },
        {
          question: (
            <>
              ¿Cuál es la mejor época para visitar Colombia? 
            </>
          ),
          answer:
            "La mejor época para visitar depende de la región. En general, la temporada seca entre diciembre y marzo es ideal, pero cada zona tiene sus propias condiciones climáticas.",
        },
        {
          question: (
            <>
              ¿Necesito vacunas específicas <span className={classes["desktop-break"]}>
            <br />
          </span>antes  <span className={classes["mobile-break"]}><br /></span> de viajar a  <span className={classes["mobile-break"]}><br /></span> Colombia? 
            </>
          ),
          answer:
            "Es recomendable verificar las vacunas necesarias con anticipación. Las vacunas contra la fiebre amarilla y otras pueden ser requeridas o recomendadas según la región que planeas visitar.",
        },
        {
          question: (
            <>
              ¿Cuál es la moneda oficial en  Colombia <span className={classes["desktop-break"]}>
            <br />
          </span> <span className={classes["mobile-break"]}><br /></span> y cómo manejar  <span className={classes["mobile-break"]}><br /></span> el dinero? 
            </>
          ),
          answer:
            "La moneda oficial es el peso colombiano. Se pueden realizar cambios en aeropuertos, bancos y casas de cambio. Además, las tarjetas de crédito son ampliamente aceptadas.",
        },
        {
          question: (
            <>
              ¿Es necesario aprender español<span className={classes["desktop-break"]}>
            <br />
          </span>para viajar  <span className={classes["mobile-break"]}><br /></span>a Colombia? 
            </>
          ),
          answer:
            "No es necesario, pero conocer algunas frases básicas en español puede facilitar la comunicación. En áreas turísticas, muchos hablan inglés, pero aprender algunas expresiones locales puede ser útil.",
        },
        {
          question: (
            <>
              ¿Cómo es el  <span className={classes["mobile-break"]}><br /></span>transporte público   <span className={classes["mobile-break"]}><br /></span> en Colombia? 
            </>
          ),
          answer:
            "Las ciudades principales tienen sistemas de transporte público. También hay opciones de taxis y servicios de transporte compartido. Para distancias más largas, hay vuelos internos y buses.",
        },
        {
          question: (
            <>
              ¿Qué tipo de enchufes se utilizan<span className={classes["desktop-break"]}>
            <br />
          </span>en Colombia <span className={classes["mobile-break"]}><br /></span>y necesito un <span className={classes["mobile-break"]}><br /></span> adaptador? 
            </>
          ),
          answer:
            "Colombia utiliza enchufes tipo A y B. Si tus dispositivos utilizan otro tipo, necesitarás un adaptador.",
        },
        {
          question: (
            <>
              ¿Cuáles son los  <span className={classes["mobile-break"]}><br /></span>platos típicos <span className={classes["desktop-break"]}>
            <br />
          </span>colombianos que  <span className={classes["mobile-break"]}><br /></span>debo probar? 
            </>
          ),
          answer:
            "Algunos platos tradicionales incluyen bandeja paisa, arepas, empanadas y ajiaco. La variedad culinaria es amplia y deliciosa.",
        },
        {
          question: (
            <>
              ¿Se puede beber  <span className={classes["mobile-break"]}><br /></span>agua del grifo   <span className={classes["mobile-break"]}><br /></span>en Colombia? 
            </>
          ),
          answer:
            "En la mayoría de las ciudades principales, el agua del grifo es potable. Sin embargo, en áreas rurales o más remotas, se recomienda beber agua embotellada.",
        },
        {
          question: (
            <>
              ¿Cómo es la   <span className={classes["mobile-break"]}><br /></span> conexión a internet   <span className={classes["mobile-break"]}><br /></span>en Colombia? 
            </>
          ),
          answer:
            "La conexión a internet es generalmente buena en las áreas urbanas. Muchos lugares ofrecen wifi gratuito, y puedes obtener una tarjeta SIM local para datos móviles durante tu estancia.",
        },
        {
          question: (
            <>
              ¿Cuál es la mejor época  para <span className={classes["desktop-break"]}>
            <br />
          </span>  <span className={classes["mobile-break"]}><br /></span> visitar la Región  <span className={classes["mobile-break"]}><br /></span>Cafetera? 
            </>
          ),
          answer:
            "La Región Cafetera tiene un clima agradable todo el año, pero la temporada seca entre diciembre y marzo es ideal para evitar lluvias.",
        },
        {
          question: (
            <>
              ¿Cuáles son las principales atracciones <span className={classes["desktop-break"]}>
            <br />
          </span>turísticas de la <span className={classes["mobile-break"]}><br /></span> Región Cafetera? 
            </>
          ),
          answer:
            "Algunos puntos destacados incluyen las fincas cafeteras, el Valle de Cocora y los pintorescos pueblos como Salento.",
        },
        {
          question: (
            <>
              ¿Cómo llegar a la Región Cafetera <span className={classes["mobile-break"]}><br /></span>desde <span className={classes["desktop-break"]}>
            <br />
          </span>las principales ciudades de <span className={classes["mobile-break"]}><br /></span> Colombia? 
            </>
          ),
          answer:
            "Puedes volar a los aeropuertos de Armenia, Pereira o Manizales. También hay opciones de autobús desde ciudades cercanas.",
        },
        {
          question: (
            <>
              ¿Hay tours guiados para aprender  sobre <span className={classes["mobile-break"]}><br /></span> <span className={classes["desktop-break"]}>
            <br />
          </span>el proceso del café  <span className={classes["mobile-break"]}><br /></span>en la Región <span className={classes["mobile-break"]}><br /></span>Cafetera? 
            </>
          ),
          answer:
            "Sí, hay numerosos tours que te llevan a fincas cafeteras donde podrás aprender sobre el cultivo, la cosecha y la producción de café.",
        },
        {
          question: (
            <>
              ¿Cuál es la mejor manera de explorar <span className={classes["desktop-break"]}>
            <br />
          </span><span className={classes["mobile-break"]}><br /></span>los paisajes de la Región Cafetera? 
            </>
          ),
          answer:
            "Puedes hacer caminatas, paseos a caballo o tours en jeep para explorar las colinas, plantaciones y valles de la región.",
        },
        {
          question: (
            <>
              ¿Cuáles son los principales festivales<span className={classes["desktop-break"]}>
            <br />
          </span> <span className={classes["mobile-break"]}><br /></span> o eventos en la <span className={classes["mobile-break"]}><br /></span>Región Cafetera? 
            </>
          ),
          answer:
            "Eventos como la Feria de Manizales ofrecen experiencias culturales y festivas. También hay celebraciones locales en pueblos cercanos.",
        },
        {
          question: (
            <>
              ¿Cómo es la oferta <span className={classes["mobile-break"]}><br /></span>de alojamiento <span className={classes["desktop-break"]}>
            <br />
          </span> <span className={classes["mobile-break"]}><br /></span> en la Región <span className={classes["mobile-break"]}><br /></span>Cafetera? 
            </>
          ),
          answer:
            "Hay una variedad de opciones, desde fincas tradicionales hasta alojamientos boutique. Se pueden encontrar opciones desde económicas hasta lujosas.",
        },
        {
          question: (
            <>
              ¿Cuáles son las opciones  para <span className={classes["mobile-break"]}><br /></span>los amantes <span className={classes["desktop-break"]}>
            <br />
          </span>de la aventura  en la <span className={classes["mobile-break"]}><br /></span>Región Cafetera? 
            </>
          ),
          answer:
            "Además de los tours de café, hay oportunidades para practicar senderismo, parapente y explorar las montañas.",
        },
      ],
    },
  };

  return (

    <main className={classes["main-content"]}>
        <SEO
        title={content[lang].seoTitle}
      />
      <section
        className={`${classes["flex-row"]} ${classes["section-banner"]} ${classes.avistamiento}`}
      >
        <div className={`${classes["desc-right"]} ${classes["flex-col"]}`}>
          <img
            src={dividerh}
            alt="divider"
            className={`${classes.img} ${classes.divider}`}
          />
          <h2>{content[lang].title}</h2>
          <img
            src={dividerh}
            alt="divider"
            className={classes["div-inverse"]}
          />
        </div>

        <div className={classes["img-left"]}>
          <img src={PR} alt="Imagen del plan" className={classes.img} />
        </div>
      </section>

      <div className={classes['faq-box-wrapper']}>
      {content[lang].questions.map((item, index) => (
        <div key={index} className={classes['faq-box-item']}>
          {/* Botón para la pregunta */}
          <button
            type="button"
            className={classes['faq-box-question']}
            onClick={() => toggleAnswer(index)}
          >
            <h2>{item.question} </h2>
            <span
            className={
              activeIndex === index
                ? classes["chevron-icon-active-FAQ"]
                : classes["chevron-icon-FAQ"]
            }
          ></span>
          </button>
          {/* Respuesta que se despliega */}
          <div
            className={`${classes['faq-box-answer']} ${
              activeIndex === index ? classes.visible : classes.hidden
            }`}
          >
            <div className={classes['faq-box-answer-content']}>
            <img src={li} alt="icono" className={classes['img-icon']} />
              <span>{item.answer}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
    </main>
  );
};

export default FAQAccordion;
