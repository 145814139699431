import React from 'react';
import { Helmet } from 'react-helmet-async';

function SEO({title, description, name, image}) {
    return (
        <Helmet>
        { /* Standard metadata tags */ }
        <title>{title}</title>
        <meta name='description' content={description} />
        { /* Facebook tags */ }
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property='og:image' content={image}/>
        <meta property="og:url"  content={window.location.href} />
        { /* Twitter tags */ }
        <meta property="twitter:creator" content={name} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        { /* End Twitter tags */ }
        </Helmet>
        );
};

export default SEO;