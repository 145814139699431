import classes from '../../css/styles.module.css'; 
import whattsappLogo from '../../img/whatsapp-logo.svg';
import instagramLogo from '../../img/instagram-logo.svg';
import i18next from 'i18next';

const Contacto = () => {

  const lang = i18next.language.split('-')[0] || 'en';

  const content = {
      en: {
          description: "Embark on a unique journey with Eco Rolling, where every moment is an opportunity to explore the wonder of Colombian biodiversity.",
      },
      es: {
          description: "Embárcate en una travesía única con Eco Rolling, donde cada momento es una oportunidad para explorar la maravilla de la biodiversidad colombiana.",
      }
  };
  return (
    <section>
    <div className={`${classes.contacto} ${classes['flex-col']}`}>
      <div className={`${classes['contact-wrapper']} ${classes['flex-col']}`}>
        <h1 className={`${classes['project-title']} ${classes.white}`}>{i18next.t('Contáctanos')}</h1>
        <h2 className={`${classes['card-title']} ${classes.white} ${classes['section-desc']} ${classes["contact-desc"]} `}>{content[lang].description}</h2>
        <div className={`${classes['flex-row']} ${classes['contact-btns-wrapper']}`}>
        <a href={i18next.t('whatsappLink')} target="_blank" rel="noreferrer" >
            <button className={`${classes['main-btn']} ${classes['btn-ghost']}`}>
              <img src={whattsappLogo} alt="whatsapp-logo" className={`${classes['img-xxs']}`} /> WhatsApp 
            </button>
          </a>
          <a href="https://www.instagram.com/ecorolling_/" target="_blank" rel="noreferrer" >
            <button className={`${classes['main-btn']} ${classes['btn-ghost']}`}>
              <img src={instagramLogo} alt="instagram-logo" className={`${classes['img-xxs']}`} /> Instagram 
            </button>
          </a>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Contacto;